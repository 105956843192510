
export default {
  data() {
    return {
      //totalClap: "",
    }
  },
  props: {
    claps: {
      type: [Number, String],
      default: 0
    },
  },
  created() { },
}
