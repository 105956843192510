import { render, staticRenderFns } from "./HomeHero.vue?vue&type=template&id=79444c7a&scoped=true&"
import script from "./HomeHero.vue?vue&type=script&lang=js&"
export * from "./HomeHero.vue?vue&type=script&lang=js&"
import style0 from "./HomeHero.vue?vue&type=style&index=0&id=79444c7a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79444c7a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SkeletonLoader: require('/codebuild/output/src1821477155/src/0707-vue/components/SkeletonLoader.vue').default,Img: require('/codebuild/output/src1821477155/src/0707-vue/components/Img.vue').default,VimeoPlayer: require('/codebuild/output/src1821477155/src/0707-vue/components/VimeoPlayer.vue').default})
