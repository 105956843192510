
import { vueVimeoPlayer } from 'vue-vimeo-player'

export default {
  components: { vueVimeoPlayer },
  props: {
    videoId: [Number, String],
    playing: Boolean,
    background_color: String,
    close_button_color: String,
  },
  computed: {
    playingStatus() {
      return this.playing
    },
  },
  watch: {
    playingStatus(newValue, oldValue) {
      newValue ? this.$refs.player.play() : this.$refs.player.pause()
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.player.play()
      this.$store.commit('countUp')
    })
  },
  methods: {
    closeVideoPlayer() {
      this.$nextTick(() => {
        this.$emit('closeVideoPlayer')
      })
    },
  },
}
