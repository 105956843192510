
export default {
  props: {
    post_id: [String, Number],
    title: String,
    post_slug: String,
    image: String,
    clap_count: [Number, String],
    border: Boolean,
    defaultX: String,
  },
  data() {
    return {
      totalReadTime: '',
      y: '0px',
      hover: false,
    }
  },
  computed: {
    imageClass() {
      let Classes = 'post-img'
      if (this.border) {
        Classes = Classes + ' border'
      }
      return Classes
    },
  },
  mounted() {
    this.$refs.item?.$el?.addEventListener('mousemove', this.handleMouseMove)
    this.$refs.item?.$el?.addEventListener('mouseover', this.handleMouseOver)
    this.$refs.item?.$el?.addEventListener('mouseleave', this.handleMouseOut)
  },
  created() {
    this.post_url = '/insights/' + this.post_slug + '/'
  },
  methods: {
    handleMouseMove(e) {
      const imgWidth = parseInt(this.$refs.cursor?.offsetWidth / 2)
      const imgHeight = parseInt(this.$refs.cursor?.offsetHeight)
      this.y = e.offsetY - imgHeight + 'px'
      this.$emit('updateX', e.offsetX - imgWidth + 'px')
    },
    handleMouseOver(e) {
      if (this.hover) return
      setTimeout(() => {
        this.hover = true
      }, 50)
    },
    handleMouseOut(e) {
      if (!this.hover) return
      setTimeout(() => {
        this.hover = false
      }, 50)
    },
  },
}
