
import Slick from 'vue-slick'

export default {
  components: {
    Slick,
  },
  data() {
    return {
      activeIndex: null,
      updating: false,
      slickOptions: {
        arrows: false,
        dots: false,
        infinite: false,
        loop: false,
        lazyLoad: 'ondemand',
        slidesToScroll: 1,
        autoplay: false,
        swipeToSlide: true,
        asNavFor: '.author-avatars-slick',
        initialSlide: 2,
      },
      avatarsOptions: {
        arrows: false,
        dots: false,
        infinite: false,
        loop: false,
        slidesToScroll: 1,
        autoplay: false,
        swipe: false,
        swipeToSlide: false,
        initialSlide: 2,
        asNavFor: '.home-testimonials-slider',
        centerMode: true,
        variableWidth: true,
        draggable: false,
      },
    }
  },
  computed: {
    testimonials() {
      const items =
        this.$config.home.home_testimonials.length % 2 === 0
          ? this.$config.home.home_testimonials.slice(
              0,
              this.$config.home.home_testimonials.length - 1
            )
          : this.$config.home.home_testimonials
      return items.slice(0,5).map((item) => {
        return {
          title: item.case_studies_id.testimonial_author_title,
          name: item.case_studies_id.testimonial_author_name,
          description: '“' + item.case_studies_id.testimonial_description + '“',
          image: item.case_studies_id.author_rounded_avatar.filename_disk,
        }
      })
    },

    activeItem() {
      return (
        this.testimonials[this.activeIndex] ?? {
          title: ' ',
          name: ' ',
          description: ' ',
          image: ' ',
        }
      )
    },
  },
  mounted() {
    this.activeIndex = Math.ceil(this.testimonials.length / 2) - 1
    // setTimeout(() => {
    //   this.$refs.slick.goTo(this.activeIndex)
    // }, 1000)
    // scroll to middle
  },
  methods: {
    setActiveItem(index) {
      this.$refs.slick.goTo(index)
    },
    handleAfterChange(event, slick, currentSlide) {
      this.activeIndex = currentSlide
    },
  },
}
