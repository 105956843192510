
export default {
  props: {
    title: String,
    items: [Array, Object],
  },
  data() {
    return {}
  },
  computed: {
    orderedCases() {
      const homeCaseStudies = this.items.map((item) => {
        return this.$config.case_studies.filter(
          (cs) => cs.id === item.case_studies_id
        )[0]
      })
      return homeCaseStudies
    },
  },
  mounted() {},
  methods: {},
}
