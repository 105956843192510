
export default {
    props: {
        loaded: {
            type: Boolean,
            default: false
        }
    },

}
