
import orderBy from 'lodash-es/orderBy'
export default {
  props: {
    services: [Array, Object],
    title: String,
  },
  computed: {
    orderedServices() {
      return orderBy(this.services, 'order', 'asc')
    },
  },
}
