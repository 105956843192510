
export default {
  props: {
    navmenu: [Array, Object],
    navdata: [Array, Object],
  },
  data() {
    return {
      menuExpanded: false,
      dirty: null,
      winWidth: 0,
      sticky: false,
      hide: false,
      oldScroll: 0,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, { passive: true })
    if (typeof window !== 'undefined') {
      this.winWidth = window.innerWidth
      window.addEventListener(
        'resize',
        () => {
          this.winWidth = window.innerWidth
        },
        { passive: true }
      )
    }
  },
  methods: {
    handleScroll(event) {
      const direction =
        this.oldScroll > event.currentTarget.scrollY ? 'up' : 'down'
      this.oldScroll = event.currentTarget.scrollY
      this.$nextTick(() => {
        this.sticky = event.currentTarget.scrollY > 50
        if (event.currentTarget.scrollY > 200) {
          setTimeout(() => {
            this.hide = direction == 'down'
          }, 300)
        }
      })
    },
    toggleNavMenu() {
      this.menuExpanded = !this.menuExpanded
    },
  },
  watch: {
    '$route.fullPath'(newValue, oldValue) {
      this.hide = false
    },
  },
}
