
export default {
  data() {
    return {
      loaded: false,
    }
  },
  methods: {
    handleLoad() {
      this.loaded = true
      this.$emit('load')
    },
  },
  props: {
    loading: {
      type: String,
      default: 'lazy',
    },
    preload: {
      type: Boolean,
      default: false,
    },
    format: {
      type: String,
      default: 'webp',
    },
    quality: {
      type: Number,
      default: 80,
    },

    width: {
      type: Number,
      default: 100,
    },
    height: {
      type: Number,
      default: 100,
    },
    src: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: '',
    },
    defaultSizes: String,
    provider: String,
  },
  computed: {
    sizes() {
      return `mobile:90vw tablet:${this.width / 12}vw desktop:${
        this.width / 12
      }vw fullhd:${this.width * this.devicePixelRatio}px 4k:${
        this.width * this.devicePixelRatio
      }px`
    },
    devicePixelRatio() {
      if (process.client) return parseInt(window.devicePixelRatio)
      return 1
    },
  },
}
