
export default {
  data() {
    return {}
  },
  methods: {
    hidePopup() {
      this.$store.commit('hideMeetingPopup')
    },
  },
}
