
import Vue from 'vue'
import global from '../mixins/global'
Vue.mixin(global)
export default {
  data() {
    return {
      scriptsLoaded: false
    }
  },
  mounted() {
    ['scroll', 'keyup'].forEach(event => {
      window.addEventListener(event, () => {
        setTimeout(() => {
          const scripts = ['https://js.hs-scripts.com/20656520.js']
          this.injectScript(scripts)
          this.loadScripts()
        }, 1000);
      }, { once: true, passive: true });
      this.$nextTick(() => {
        this.fetchClaps()
        this.$store.commit('hideMeetingPopup')
      })
    });
  },
  
  methods: {
    loadScripts() {
      !function (f, b, e, v, n, t, s) {
        if (f.fbq) return; n = f.fbq = function () {
          n.callMethod ?
            n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        };
        if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
        n.queue = []; t = b.createElement(e); t.async = !0;
        t.src = v; s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s)
      }(window, document, 'script',
        'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', 644892816573988);
      fbq('track', 'PageView');

      (function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push({
          'gtm.start':
            new Date().getTime(), event: 'gtm.js'
        }); var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);

      })(window, document, 'script', 'dataLayer', 'GTM-T5MP2PK');

      const _linkedin_partner_id = "2773626";
      window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
      window._linkedin_data_partner_ids.push(_linkedin_partner_id);
      (function (l) {
        if (!l) {
          window.lintrk = function (a, b) { window.lintrk.q.push([a, b]) };
          window.lintrk.q = []
        }
        var s = document.getElementsByTagName("script")[0];
        var b = document.createElement("script");
        b.type = "text/javascript"; b.async = true;
        b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
        s.parentNode.insertBefore(b, s);
      })(window.lintrk);
      this.scriptsLoaded = true

    }
  },
  watch: {
    '$route.fullPath'(newValue, oldValue) {
      this.fetchClaps()
    }
  },

}
