
import Vue from 'vue'

import VueMask from 'v-mask'
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)
Vue.use(VueMask)
import { required, email, minLength, helpers } from 'vuelidate/lib/validators'
const phoneFormat = helpers.regex(
  'phoneFormat',
  /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/
)
export default {
  data() {
    return {
      first_name: '',
      last_name: '',
      email: '',
      message: '',
      company: '',
      phone: '',
      website: '',
      submitStatus: null,
    }
  },
  props: {
    service: String,
    default: '',
  },
  validations: {
    first_name: {
      required,
      minLength: minLength(2),
    },
    last_name: {
      required,
      minLength: minLength(2),
    },
    phone: {
      phoneFormat,
    },
    company: {
      required,
    },
    message: {
      required,
    },
    email: {
      required,
      email,
    },
  },
  computed: {
    pageUrl() {
      return window.location.href
    },
    pageTitle() {
      return document.title
    },
    utmSource() {
      const options = [
        // { source: "website", lead: "Website" },
        // { source: "referral", lead: "Referral" },
        { source: 'email-campaign', lead: 'Email Campaign' },
        { source: 'google-ads', lead: 'Google Ads' },
        { source: 'linkedin', lead: 'LinkedIn' },
        { source: 'facebook', lead: 'Facebook' },
        { source: 'display-ad', lead: 'Display Ad' },
        { source: 'retargeting', lead: 'Retargeting' },
        { source: 'newsletter-subscription', lead: 'Newsletter Subscription' },
      ]
      const lead = options.filter(
        (opt) => opt.source === this.$route.query.utm_source
      )[0]
      return lead ? lead.lead : 'Direct'
    },
  },
  methods: {
    submit() {
      // Do Validation
      this.$v.$touch()
      if (this.$v.$invalid) {
        // Validation errors
        this.submitStatus = 'ERROR'
        console.log('ERROR')
      } else {
        this.submitStatus = 'PENDING'

        // Build form data
        const formData = {
          fields: [
            {
              name: 'firstname',
              value: this.first_name,
            },
            {
              name: 'lastname',
              value: this.last_name,
            },
            {
              name: 'company',
              value: this.company,
            },
            {
              name: 'email',
              value: this.email,
            },
            {
              name: 'phone',
              value: this.phone,
            },
            {
              name: 'message',
              value: this.message,
            },
            {
              name: 'website',
              value: this.website,
            },
            {
              name: 'service_s__interested_in',
              value: this.service
                ? this.service
                : this.$store.state.selectedPurpose,
            },
            {
              name: 'lead_source',
              value: this.utmSource,
            },
          ],
          context: {
            pageUri: this.$router.currentRoute.fullPath,
            pageName: document.title,
            ipAddress: '192.168.1.1',
          },
        }


        this.$axios
          .$post(
            'https://api.hsforms.com/submissions/v3/integration/submit/20656520/e5ecd56d-899b-486b-8f36-7b68b8688a9c',
            formData
          )
          .then((res) => {
            this.$emit('success')
            this.showNotification(
              'Your request has been received. We will be in touch soon.',
              'success'
            )
            window.dataLayer.push({
              event: 'formSubmitted',
              formName: 'Request Quote',
            })
            this.submitStatus = 'OK'
            window.scrollTo(0, 0)
          })
          .catch((error) => {
            console.log(error.response)
            this.showNotification('Something went wrong', 'error')
          })
      }
    },
  },
}
