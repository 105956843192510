
import debounce from 'lodash-es/debounce'
export default {
  props: {
    cs: [Array, Object],
    index: Number,
    sidebar: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      inViewport: false,
      hover: false,
      loaded: false,
      translateX: '',
      translateY: '',
      tiltOptions: {
        max: 3,
        speed: 500,
        scale: 1.05,
      },
    }
  },
  computed: {
    csSlug() {
      return this.cs.slug
        ? this.cs.slug
        : `${this.slugify(this.cs.title)}-case-study`
    },
    videoHeight() {
      return parseInt(
        (this.cs.thumbnail_image.height * 500) / this.cs.thumbnail_image.width
      )
    },
    isTouchDevice() {
      if (typeof window !== 'undefined') {
        return (
          'ontouchstart' in window ||
          navigator.maxTouchPoints > 0 ||
          navigator.msMaxTouchPoints > 0
        )
      }
      return false
    },
  },
  mounted() {
    this.$refs.cs_thumbnail.addEventListener(
      'mousemove',
      debounce(this.handleMouseMove, 5)
    )
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    playAnimation() {
      //   if (!this.$refs.thumbnail?.paused) return
      if (!this.hover) this.hover = true
      setTimeout(() => {
        this.$refs.thumbnail.play()
      }, 300)
    },
    pauseAnimation() {
      //   if (this.$refs.thumbnail?.paused) return
      this.hover = false
      setTimeout(() => {
        this.$refs.thumbnail.currentTime = 0
        this.$refs.thumbnail.pause()
      }, 600)
    },
    handleClick(e) {
      console.log(e)

      e.preventDefault()
      // if (this.isTouchDevice) {
      // }
    },
    handleMouseMove(e) {
      this.translateX = `calc(${e.offsetX}px - 50%)`
      this.translateY = `calc(${e.offsetY}px - 50%)`
    },
    handleScroll(e) {
      const rect = this.$refs.home_cs_item.getBoundingClientRect()
      if (rect.top < window.innerHeight * 0.7) {
        this.inViewport = true
      }
    },
  },
}
