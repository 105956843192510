
export default {
  data() {
    return {
      formSubmitted: false,
    }
  },
  methods: {
    hidePopup() {
      this.$store.commit('hideAskQuestionPopup')
    },
  },
}
