
import orderBy from 'lodash-es/orderBy'
import Vue from 'vue'
import VueScrollTo from 'vue-scrollto'
Vue.use(VueScrollTo)
export default {
  props: {
    title: String,
    faqs: [Array, Object],
    page: String,
  },
  data() {
    return {
      activeCapabilityIndex: -1,
    }
  },
  computed: {
    orderedFaqs() {
      return orderBy(
        !this.page
          ? this.faqs
          : this.faqs.filter((f) => f.pages.includes(this.page)),
        'order',
        'asc'
      )
    },
  },
  methods: {
    expandAccordion(index) {
      this.activeCapabilityIndex == index
        ? (this.activeCapabilityIndex = -1)
        : (this.activeCapabilityIndex = index)
      setTimeout(() => {
        this.$scrollTo('.accordion-item.active h4', 500, {
          x: false,
          y: true,
          offset: -88,
        })
      }, 101)
    },
  },
}
