
export default {
  props: {
    title: String,
    subtitle: String,
    features: [Array, Object],
    ctaTitle: String,
    items: [Array, Object],
  },
}
