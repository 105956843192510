
export default {
  props: {
    blogtitle: String,
    posts: [Array, Object],
    postsToShow: Number,
  },
  data() {
    return {
      loadedPosts: [],
      defaultX: '0px',
    }
  },
  computed: {},

  created() {
    this.loadedPosts.push(...this.posts.slice(0, this.postsToShow))
  },
}
