import { Notyf } from 'notyf'
import kebabCase from 'lodash-es/kebabCase'
import startCase from 'lodash-es/startCase'
// import { Directus } from '@directus/sdk'

const notyf = () => {
  return (
    process.client &&
    new Notyf({
      types: [
        {
          className: 'success',
          type: 'notyf-success',
          icon: {
            className: 'icon-success',
            tagName: 'span',
            text: 'Success!',
          },
        },
        {
          className: 'info',

          type: 'notyf-info',
          icon: {
            className: 'icon-info',
            tagName: 'span',
            text: 'Info!',
          },
        },
        {
          className: 'warning',

          type: 'notyf-warning',
          icon: {
            className: 'icon-warning',
            tagName: 'span',
            text: 'Warning!',
          },
        },
        {
          className: 'error',

          type: 'notyf-error',
          icon: {
            className: 'icon-error',
            tagName: 'span',
            text: 'Error!',
          },
        },
      ],
      position: {
        x: 'right',
        y: 'top',
      },
      duration: 3000,
      dismissible: false,
    })
  )
}

export default {
  data() {
    return {
      notyf: notyf(),
      scriptsInjected: false
    }
  },
  methods: {
    injectScript(scripts) {
      if (this.scriptsInjected) return
      console.log("inject");
      scripts.map(script => {
        let scriptElem = document.createElement('script')
        scriptElem.setAttribute('src', script)
        scriptElem.setAttribute('defer', 'defer')
        document.head.appendChild(scriptElem)
      })
      this.scriptsInjected = true

    },
    async fetchClaps() {
      try {
        const claps = await this.$axios.$get(this.$config.api + 'blogs/?fields=id,clap_count&limit=-1')
        this.$store.commit('setClaps', claps.data)
      } catch (error) {

      }
    },
    getClap(id) {
      return this.$store.state.clapsData.filter(item => item.id == id)[0]?.clap_count || ''
    },

    showNotification(text, type) {
      this.notyf.open({
        type: 'notyf-' + type,
        message: text,
      })
    },
    slugify(str) {
      return kebabCase(str)
    },
    slugToString(str) {
      return startCase(str)
    },
    getDate(date) {
      return date
        ? new Date(date).toLocaleDateString('en-us', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        })
        : ''
    },
    getShareUrl(media) {
      const shareUrl = this.$config.seo_settings.website_url + this.$route.fullPath.slice(1)
      switch (media) {
        case 'facebook':
          return (
            'https://www.facebook.com/sharer/sharer.php?u=' +
            shareUrl
          )
        case 'twitter':
          return (
            'https://twitter.com/intent/tweet?url=' + shareUrl
          )
        case 'linkedin':
          return (
            'https://www.linkedin.com/shareArticle?mini=true&url=' +
            shareUrl
          )
        default:
          return ''
      }
    },
    async getPostComments(id) {
      try {
        // get directus raw comments
        const directusComments = await this.$axios.$get(
          this.$config.api +
          `/blog_comments?filter[blog][_eq]=${id}&filter[status][_eq]=approved&sort=id&fields=*`
        ) // invoke data
        if (!directusComments.data) return [null, 0]
        let postComments = directusComments.data // Build comments and replies tree
        postComments.map((c, i) => {
          // Loop throuth all comments
          if (c.parent_comment_id) {
            // If comment is a reply (comment id is not equal zero)
            const parent = postComments.findIndex(
              (comment) => comment.id == c.parent_comment_id
            ) // Find reply parent comment using id
            if (parent) {
              // if parent comment exists
              if (postComments[parent].replies) {
                // Pushing replies to existing replies object
                postComments[parent].replies.push(c)
              } else {
                // Creating replies object for the very first time
                postComments[parent] = { ...postComments[parent], replies: [c] }
              }
              postComments = postComments.filter((cm) => cm.id != c.id) // Remove replies from original comments object and keep only parent comments
            }
          }
        })
        return [postComments, directusComments.data.length] // Return comments object + total comments count
      } catch (error) {
        console.log(error)
      }
    },
    async getItem(collection, id) {
      try {
        const items = await this.$axios.$get(this.$config.api + `${collection}?filter[id][_eq]=${id}`)
        if (!items.data[0]) return
        return items.data[0]
      } catch (e) {
        console.log(e)
      }
    },
    async updateItem(collection, id, payload) {
      try {
        const items = await this.$axios.$patch(this.$config.api + `${collection}/${id}`, payload)
        return items.data

      } catch (e) {
        console.log(e)
      }
    },
    async postItem(collection, payload) {
      try {
        const item = await this.$axios.$post(this.$config.api + collection,
          payload
        )
        return item.data
      } catch (e) {
        console.log(e)
      }
    },
    checkAuthentication() {
      if (!this.$store.state.isAuthenticated) {
        this.$store.commit('showLoginPopup')
      }
    },
    async signInGoogleHandler(firebase) {
      const response = await this.signInGoogle(firebase)
      if (response.user) {
        this.$store.commit('setAuth', true)
        this.$store.commit('closeAuthPopup')
        this.$store.commit('setUser', response.user.toJSON())
      }
    },
    async signInFacebookHandler(firebase) {
      const response = await this.signInFacebook(firebase)
      if (response.user) {
        this.$store.commit('setAuth', true)
        this.$store.commit('closeAuthPopup')
        this.$store.commit('setUser', response.user.toJSON())
      }
    },
    async signInTwitterHandler(firebase) {
      const response = await this.signInTwitter(firebase)
      if (response.user) {
        this.$store.commit('setAuth', true)
        this.$store.commit('closeAuthPopup')
        this.$store.commit('setUser', response.user.toJSON())
      }
    },
    async signInGoogle(firebase) {
      try {
        if (!firebase) return
        const provider = new firebase.auth.GoogleAuthProvider()
        return await firebase.auth().signInWithPopup(provider)
      } catch (error) {
        // console.log(error);
        // Handle Errors here.
        const errorCode = error.code
        const errorMessage = error.message
        this.showNotification(errorMessage, 'error')
        // The email of the user's account used.
        const email = error.email
        // The firebase.auth.AuthCredential type that was used.
        const credential = error.credential
        return error
      }
    },
    async signInFacebook(firebase) {
      try {
        const provider = new firebase.auth.FacebookAuthProvider()
        if (!firebase) return
        return await firebase.auth().signInWithPopup(provider)
      } catch (error) {
        // Handle Errors here.
        const errorCode = error.code
        const errorMessage = error.message
        this.showNotification(errorMessage, 'error')
        // The email of the user's account used.
        const email = error.email
        // The firebase.auth.AuthCredential type that was used.
        const credential = error.credential
        return error
      }
    },
    async signInTwitter(firebase) {

      try {
        const provider = new firebase.auth.TwitterAuthProvider()
        if (!firebase) return
        return await firebase.auth().signInWithPopup(provider)
      } catch (error) {
        // Handle Errors here.
        const errorCode = error.code
        const errorMessage = error.message
        this.showNotification(errorMessage, 'error')
        // The email of the user's account used.
        const email = error.email
        // The firebase.auth.AuthCredential type that was used.
        const credential = error.credential
        return error
      }
    },
    logout() {
      this.$store.commit('logOutUser')
    },
    async sendComments(title, postId, commentText, parentCommentId, status) {
      try {
        if (this.commentText != '') {
          const currentUser = this.$store.state.currentUser
          let email = ''
          if (!currentUser.email) {
            email = currentUser.providerData[0].email // for twitter login
          } else {
            email = currentUser.email
          }
          const payload = {
            blog: postId,
            comments: commentText,
            parent_comment_id: parentCommentId,
            status,
            user_email: email,
            user_name: currentUser.displayName,
            user_pic: currentUser.photoURL,
            likes: 0,
            flag: 0,
          }
          const response = await this.postItem('blog_comments', payload).catch(
            (e) => {
              this.showNotification(e, 'error')
            }
          )
          return response
        }
      } catch (error) {
        console.log(error)
        this.showNotification(error, 'error')
      }
    },
    getHeadData(payload, overrideSeoImage = false) {
      if (this.$config.seo_settings) {
        const seoUrl =
          this.$config.seo_settings.website_url + this.$route.fullPath.slice(1)
        const seoFallbackImage = overrideSeoImage ? payload.image.filename_disk : this.$config.seo_settings.seo_image.filename_disk
        const seoImage = payload.seo && payload.seo.seo_image
          ? payload.seo.seo_image.filename_disk
          : seoFallbackImage;

        const seoTitle =
          payload.seo && payload.seo.seo_title
            ? payload.seo.seo_title
            : this.$config.seo_settings.seo_title
        const seoDescription =
          payload.seo && payload.seo.seo_description
            ? payload.seo.seo_description
            : this.$config.seo_settings.seo_description
        // const seoKeywords =
        //   payload.seo && payload.seo.meta_tags
        //     ? payload.seo.meta_tags.join(', ')
        //     : this.$config.seo_settings.meta_tags.join(', ')
        const seoLocale = 'en_US'
        const seoSiteName = this.$config.seo_settings.website_name
        const output = {
          title: seoTitle,
          link: [
            { type: 'image/png', sizes: '16x16', href: 'favicon.png' },
            { type: 'image/png', sizes: '32x32', href: 'favicon.png' },
            { type: 'image/png', sizes: '96x96', href: 'favicon.png' },
            {
              rel: 'apple-touch-icon-precomposed',
              hid: 'apple-touch-icon-precomposed',
              type: 'image/png',
              sizes: '76x76',
              href: 'favicon.png',
            },
            {
              rel: 'apple-touch-icon-precomposed',
              hid: 'apple-touch-icon-precomposed',
              type: 'image/png',
              sizes: '120x120',
              href: 'favicon.png',
            },
            {
              rel: 'apple-touch-icon-precomposed',
              hid: 'apple-touch-icon-precomposed',
              type: 'image/png',
              sizes: '152x152',
              href: 'favicon.png',
            },
            {
              rel: 'apple-touch-icon',
              hid: 'apple-touch-icon',
              href: 'favicon.png',
            },
            {
              rel: 'shortcut icon',
              hid: 'shortcut icon',
              href: 'favicon.ico',
            },
            {
              rel: 'preconnect',
              hid: 'preconnect',
              href: 'https://fonts.googleapis.com',
            },
            {
              rel: 'preconnect',
              hid: 'preconnect',
              href: 'https://fonts.gstatic.com',
              crossorigin: 'crossorigin',
            },
            {
              rel: 'stylesheet',
              hid: 'stylesheet',
              href: 'https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&family=Poppins:wght@300;400;500;600;700&display=swap',
              media: "print",
              onload: "this.onload=null;this.removeAttribute('media');"
            },
            {
              hid: 'canonical',
              rel: 'canonical',
              href: seoUrl,
            },
          ],
          meta: [
            { charset: 'utf-8' },
            { hid: 'viewport', name: 'viewport', content: 'width=device-width, initial-scale=1' },
            { hid: 'theme-color', name: 'theme-color', content: '#000000' },
            {
              hid: 'apple-mobile-web-app-title',
              name: 'apple-mobile-web-app-title',
              content: seoTitle,
            },
            {
              hid: 'og:title',
              name: 'og:title',
              property: 'og:title',
              content: seoTitle,
            },
            {
              hid: 'og:site_name',
              name: 'og:site_name',
              property: 'og:site_name',
              content: seoSiteName,
            },
            {
              hid: 'title',
              name: 'title',
              content: seoTitle,
            },
            {
              hid: 'description',
              name: 'description',
              content: seoDescription,
            },
            // {
            //   hid: 'keywords',
            //   name: 'keywords',
            //   content: seoKeywords,
            // },
            {
              hid: 'og:locale',
              property: 'og:locale',
              content: seoLocale,
            },
            {
              hid: 'og:type',
              property: 'og:type',
              content: 'website',
            },
            {
              hid: 'og:title',
              property: 'og:title',
              content: seoTitle,
            },
            {
              hid: 'og:description',
              property: 'og:description',
              content: seoDescription,
            },
            {
              hid: 'og:url',
              property: 'og:url',
              content: seoUrl,
            },
            {
              hid: 'og:site_name',
              property: 'og:site_name',
              content: seoSiteName,
            },
            {
              hid: 'og:image',
              property: 'og:image',
              content: this.$config.imageKitUrl + seoImage,
            },
            {
              hid: 'og:image:secure_url',
              property: 'og:image:secure_url',
              content: this.$config.imageKitUrl + seoImage,
            },
            {
              hid: 'og:image:width',
              property: 'og:image:width',
              content: '1200',
            },
            {
              hid: 'og:image:height',
              property: 'og:image:height',
              content: '630',
            },
            {
              hid: 'twitter:card',
              name: 'twitter:card',
              content: 'summary_large_image',
            },
            {
              hid: 'twitter:description',
              name: 'twitter:description',
              content: seoDescription,
            },
            {
              hid: 'twitter:title',
              name: 'twitter:title',
              content: seoTitle,
            },
            {
              hid: 'twitter:image',
              name: 'twitter:image',
              content: this.$config.imageKitUrl + seoImage,
            },
            {
              hid: 'og:image:alt',
              name: 'og:image:alt',
              content: seoTitle,
            },
            {
              hid: 'twitter:image:alt',
              name: 'twitter:image:alt',
              content: seoTitle,
            },
            {
              hid: 'google-site-verification',
              name: 'google-site-verification',
              content: 'CF3TN3R7IriXgurizXy7E1cFusEeraMZv1vAZtSDygE',
            },
          ],
          script: [
            {
              type: 'application/ld+json',
              json: {
                '@context': 'https://schema.org',
                '@type': 'LocalBusiness',
                name: '0707 Inc.',
                image: 'https://0707.agency/assets/img/logo_black.svg',
                '@id': '',
                url: 'https://0707.agency',
                telephone: '+1 (609) 598-1833',
                address: {
                  '@type': 'PostalAddress',
                  streetAddress: '331 Wheat Sheaf Way',
                  addressLocality: 'Phoenixville',
                  addressRegion: 'PA',
                  postalCode: '19426',
                  addressCountry: 'US',
                },
                geo: {
                  '@type': 'GeoCoordinates',
                  latitude: 40.1510843,
                  longitude: -75.48578839999999,
                },
                openingHoursSpecification: {
                  '@type': 'OpeningHoursSpecification',
                  dayOfWeek: [
                    'Monday',
                    'Tuesday',
                    'Wednesday',
                    'Thursday',
                    'Friday',
                  ],
                  opens: '09:00',
                  closes: '17:00',
                },
              },
            },
          ],
        }
        return output
      }
    },
  },

}
