
export default {
  data() {
    return {
      isSlider: null,
    }
  },
  head() {
    return this.getHeadData(this.$config.home)
  },
  computed: {
    serviceData() {
      return this.$config.home.services.map((service) => {
        return service.services_id
      })
    },
  },
  mounted() {
    this.setSliderStatus()
    window.addEventListener('resize', this.setSliderStatus)
  },
  methods: {
    setSliderStatus() {
      this.isSlider = window.innerWidth > 1024
    },
  },
}
