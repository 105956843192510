export default {
  state() {
    return {
      isAuthenticated: false,
      showLogin: false,
      showSignup: false,
      currentUser: [],
      comment_text: '',
      counter: 0,
      showMeetingPopup: false,
      showServicePopup: false,
      showQuotePopup: false,
      showAskQuestionPopup: false,
      clapsData: [],
      selectedPurpose: '',
      activeCaseStudy: null,
    }
  },
  mutations: {
    countUp(state) {
      state.counter = state.counter + 1
    },
    closeAuthPopup(state) {
      state.showLogin = false
      state.showSignup = false
    },
    showLoginPopup(state) {
      state.showLogin = true
    },
    showSignupPopup(state) {
      state.showSignup = true
    },
    logOutUser(state) {
      state.currentUser = ''
      state.isAuthenticated = false
    },
    setAuth(state, payload) {
      state.isAuthenticated = payload
    },
    setUser(state, payload) {
      state.currentUser = payload
    },

    showMeetingPopup(state) {
      state.showMeetingPopup = true
    },
    hideMeetingPopup(state) {
      state.showMeetingPopup = false
    },
    showQuotePopup(state) {
      state.showQuotePopup = true
    },
    showAskQuestionPopup(state) {
      state.showAskQuestionPopup = true
    },
    hideQuotePopup(state) {
      state.showQuotePopup = false
    },
    hideAskQuestionPopup(state) {
      state.showAskQuestionPopup = false
    },
    setClaps(state, payload) {
      state.clapsData = payload
    },
    selectPurpose(state, payload) {
      state.selectedPurpose = payload
    },
    setActiveCaseStudy(state, payload) {
      state.activeCaseStudy = payload
    },
  },

}
