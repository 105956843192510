
export default {
  props: {
    title: String,
    subtitle: String,
    logos: [Array, Object],
  },
  data() {
    return {}
  },

  mounted() {},

  methods: {},
}
