
import Vue from 'vue'

import VueMask from 'v-mask'
import Vuelidate from 'vuelidate'
import { required, email, minLength } from 'vuelidate/lib/validators'
Vue.use(Vuelidate)
Vue.use(VueMask)

export default {
  data() {
    return {
      first_name: '',
      last_name: '',
      email: '',
      message: '',

      submitStatus: null,
    }
  },
  props: {
    service: String,
    default: '',
  },
  validations: {
    first_name: {
      required,
      minLength: minLength(2),
    },
    last_name: {
      required,
      minLength: minLength(2),
    },
    message: {
      required,
    },
    email: {
      required,
      email,
    },
  },
  computed: {
    pageUrl() {
      return window.location.href
    },
    pageTitle() {
      return document.title
    },
  },
  methods: {
    submit() {
      // Do Validation
      this.$v.$touch()
      if (this.$v.$invalid) {
        // Validation errors
        this.submitStatus = 'ERROR'
        console.log('ERROR')
      } else {
        this.submitStatus = 'PENDING'

        // Build form data
        const formData = {
          fields: [
            {
              name: 'firstname',
              value: this.first_name,
            },
            {
              name: 'lastname',
              value: this.last_name,
            },

            {
              name: 'email',
              value: this.email,
            },
            {
              name: 'message',
              value: this.message,
            },
          ],
          context: {
            pageUri: this.$router.currentRoute.fullPath,
            pageName: document.title,
            ipAddress: '192.168.1.1',
          },
        }

        this.$axios
          .$post(
            'https://api.hsforms.com/submissions/v3/integration/submit/20656520/e98caa80-72f7-4a13-9bad-6e571ed52a62',
            formData
          )
          .then((res) => {
            this.$emit('success')
            this.showNotification(
              'Your request has been received. We will be in touch soon.',
              'success'
            )
            window.dataLayer.push({
              event: 'formSubmitted',
              formName: 'Request Quote',
            })
            this.submitStatus = 'OK'
          })
          .catch((error) => {
            console.log(error.response)
            this.showNotification('Something went wrong', 'error')
          })
      }
    },
  },
}
