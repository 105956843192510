
export default {
  props: {
    hero_data: Object,
  },
  data() {
    return {
      heroVideoPlaying: false,
      loaded: false,
    }
  },
}
